@import "./base/color";
@import "./base/typo";
@import "./base/utils";

.content-wrapper {
  @extend .flex-box;
  padding: 136px 0;
  .content {
    @include body-text-1;
    margin-left: 160px;

    .title {
      @include h4;
    }

    .description {
      margin-top: 16px;
    }

    .btn-wrapper {
      @extend .flex-box;
      margin-top: 32px;
      gap: 12px;
    }
  }
}

.content-modal-wrapper {
  color: $ss-gray-100;
  margin: 0 auto;
  width: 520px;
  padding: 32px;
  background: $ss-white;
  box-shadow: 0 1px 1px $ss-gray-40;
  border: 1px solid $ss-gray-40;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;


  .content-box {
    @include body-text-2-m;
    width: 100%;
    text-align: center;
    color: $ss-gray-90;

    .title {
      @include h6;
      margin-top: 24px;
    }

    .description {
      @include body-text-2;
      margin-top: 8px;

      .email {
        @include body-text-2-m;

      }
    }
  }
  .info-box {
    @include body-text-3;
    width: 100%;
    padding: 16px 24px;
    color: $ss-gray-80;
    background: $ss-gray-10;
    border-radius: 8px;

    .title {
      @include body-text-4-m;
      color: $ss-gray-80;
    }

    p {
      margin-top: 6px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 1079px) {
  .content-wrapper {
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;

    .content {
      @include body-text-2;
      margin-left: 0;

      .title {
        @include h7-m;
      }
      .description {
        margin-top: 24px;
      }
      .btn-wrapper {
        flex-flow: column-reverse;
        margin-top: 40px;
        gap: 12px;

        button {
          width: 100%;
        }
      }
    }
  }

  .content-modal-wrapper {
    box-shadow: none;
    border: none;
    -webkit-appearance: none;
    width: 100%;
    padding: 32px 24px;

    .btn-wrapper {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}